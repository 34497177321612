import { render, staticRenderFns } from "./Todo.vue?vue&type=template&id=272d7614&scoped=true"
import script from "./Todo.vue?vue&type=script&lang=js"
export * from "./Todo.vue?vue&type=script&lang=js"
import style0 from "./Todo.vue?vue&type=style&index=0&id=272d7614&prod&lang=scss&scoped=true"
import style1 from "./Todo.vue?vue&type=style&index=1&id=272d7614&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "272d7614",
  null
  
)

export default component.exports